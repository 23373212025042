/** @module */

export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SERVER_OFFLINE = 'AUTH_LOGOUT_SERVER_OFFLINE';
export const AUTH_IS_LOADING = 'AUTH_IS_LOADING';
export const REINIT_PASSWORD_RESET = 'REINIT_PASSWORD_RESET';
export const RESET_ENTITY_DATA = 'RESET_ENTITY_DATA';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_EXPIRED = 'RESET_PASSWORD_EXPIRED';
export const SEND_RESET_PASSWORD_EMAIL_REQUEST = 'SEND_RESET_PASSWORD_EMAIL_REQUEST';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_FAIL = 'SEND_RESET_PASSWORD_EMAIL_FAIL';

export const initialState = {
  exception: null,
  isLoading: false,
  loginName: 'guest',
  resetPasswordSuccess: null,
  resetPasswordExpired: null,
  showPasswordResetResult: false,
  showSendResetPasswordEmailSuccessMessage: false,
  showTopProducerException: false,
  token: null
};

/**
 * The user/auth redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const authReducer = (state = initialState, action = {}) => {
  const { data, isLoading, showTopProducerException } = action;
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        ...data
      };
    case AUTH_LOGOUT_SERVER_OFFLINE: // fallthrough to AUTH_LOGOUT
    case AUTH_LOGOUT:
      return initialState;
    case RESET_ENTITY_DATA:
      return state;
    case REINIT_PASSWORD_RESET:
      return {
        ...state,
        showTopProducerException: false,
        showPasswordResetResult: false,
        showSendResetPasswordEmailSuccessMessage: false,
        isLoading: false
      };
    case RESET_PASSWORD_SUCCESS:
      return { ...initialState, showPasswordResetResult: true, resetPasswordSuccess: true };
    case RESET_PASSWORD_FAIL:
      return {
        ...initialState,
        showPasswordResetResult: true,
        resetPasswordSuccess: false,
        resetPasswordExpired: false
      };
    case RESET_PASSWORD_EXPIRED:
      return {
        ...initialState,
        showPasswordResetResult: true,
        resetPasswordSuccess: false,
        resetPasswordExpired: true
      };
    case SEND_RESET_PASSWORD_EMAIL_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return { ...state, isLoading: true };
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, isLoading: false, showSendResetPasswordEmailSuccessMessage: true };
    case SEND_RESET_PASSWORD_EMAIL_FAIL:
      return { ...state, isLoading: false, showSendResetPasswordEmailSuccessMessage: false, showTopProducerException };
    case AUTH_IS_LOADING:
      return { ...state, isLoading };
    default:
      return state;
  }
};

import classnames from 'classnames';
import { string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeAIDialog, getResponse, openAIDialog, setAIContent, updateMessages } from '../../../actions/openai';
import { COLOR_BLUE, COLOR_GREEN_LIGHT, COLOR_RED, DEFAULT_COLOR_LIST } from '../../../constants/preferences';
import { AIConvo } from '../../AIConvo/AIConvo';
import { Button, ButtonGroup } from '../../Button';
import { Dialog, DialogHeader } from '../../Dialog';
import { Form } from '../../Form';
import { FormField } from '../../FormField';
import { FormFieldset } from '../../FormFieldset';
import { FormFooter } from '../../FormFooter';
import { FormRow } from '../../FormRow';
import { ListPlaceholder } from '../../List';
import { Tag, TagGroup } from '../../Tag';
import styles from './AIDialog.css';
import {
  EMAIL_SYSTEM_PROMPT,
  PRESET_PROMPTS,
  STYLE_DESCRIPTIONS,
  TEMPLATE_SYSTEM_PROMPT,
  TEXTING_SYSTEM_PROMPT
} from '../../../data/openai';
import shuffle from 'lodash/shuffle';
import { KEYCODE_MAP } from '../../../constants';
import { removeAssistantMessages } from '../../../utils/aiAuthor';
import { showDrawer } from '../../../actions/drawer';
import { TaskType } from '../../TaskType';
import { FormRadio } from '../../FormRadio';
import startCase from 'lodash/startCase';
import { isFieldFilled } from '../../../utils/validation';

import { AIEula } from './AIEula';
import { buildTestId } from '../../../utils';

const shuffledColors = shuffle(DEFAULT_COLOR_LIST);
export const AI_TEST_ID = 'aiAuthor';

const AIDialog = props => {
  const { onClick, type: propsType } = props;

  const inputRef = useRef();

  const dispatch = useDispatch();

  const { messages: messagesHistory, isOpen, conversationIds } = useSelector(store => store.aiAuthor);
  const { AI } = useSelector(store => store.settings.eula) || {};
  const isEulaValid = AI?.accepted === true;

  const [isLoading, setIsLoading] = useState(false);

  const { userInfo } = useSelector(store => store.userProfile);
  const { displayFirstName, firstName } = userInfo || {};
  const { userId } = useSelector(state => state.user);

  const [type, setType] = useState(propsType || 'email');
  const currentHistory = messagesHistory?.[type];
  const hasMessageHistory = currentHistory?.length > 2;

  const name = displayFirstName || firstName ? `, ${displayFirstName || firstName}` : '';

  const shouldShowTypeSelection = propsType == null;

  const getInitialMessages = (type, name) => {
    const getSystemPrompt = type => {
      if (type === 'template') {
        return TEMPLATE_SYSTEM_PROMPT;
      }
      if (type === 'texting') {
        return TEXTING_SYSTEM_PROMPT;
      }
      return EMAIL_SYSTEM_PROMPT;
    };
    return [
      {
        role: 'system',
        content: getSystemPrompt(type)
      },
      { role: 'assistant', content: `Hi${name}, how can I help with your ${type} today?` }
    ];
  };
  const [messages, setMessages] = useState(getInitialMessages(type, name));
  const isNewConvo = messages?.length <= 2;
  const lastMessage = messages[messages.length - 1];

  const disableUseInEditor =
    messages.length <= 2 || (lastMessage?.content?.includes("I'm sorry") && lastMessage?.role === 'assistant');

  const showEnhancement = lastMessage?.role === 'assistant' && messages.length > 2;

  const [inputMessage, setInputMessage] = useState('');
  const isAskAIEnabled = messages.length < 30 && inputMessage?.length > 3;

  const [selectedText, setSelectedText] = useState('');

  useEffect(() => {
    if (type) {
      setMessages(getInitialMessages(type, name));
    }
  }, [type, name]);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection) {
      const selectedString = selection.toString().trim();
      setSelectedText(selectedString);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const onChange = e => {
    e.preventDefault(e);
    setInputMessage(e.target.value);
  };

  /**
   * Take only the new message, append and maintain the chat history in redux
   * @param {string} message - new message
   */
  const sendMessage = (message, mode) => {
    const isRegenerate = mode === 'regenerate';
    const newMessages = isRegenerate
      ? removeAssistantMessages(messages)
      : [...messages, { role: 'user', content: message }];

    if (!isRegenerate) {
      setMessages(newMessages);
    }

    try {
      dispatch(
        getResponse(
          { messages: newMessages, user: userId, conversationId: conversationIds?.[type], type },
          setIsLoading
        )
      ).then(({ response, conversationId }) => {
        const updatedMsg = [...(isRegenerate ? messages : newMessages), response];

        dispatch(updateMessages(updatedMsg, type, conversationId));
        setMessages(updatedMsg);
        setInputMessage(''); // Clear input after sending
        return;
      });
    } catch (error) {
      console.error('Error getting response from AI:', error);
    }
  };

  const handleSuggestions = (e, selectionId) => {
    e.preventDefault();
    if (PRESET_PROMPTS?.[selectionId]) {
      sendMessage(PRESET_PROMPTS?.[selectionId]);
    }
  };

  const handleStyleSuggestions = selectionId => {
    if (selectionId === 'regenerate') {
      sendMessage(null, selectionId);
    }
    if (STYLE_DESCRIPTIONS?.[selectionId]) {
      sendMessage(STYLE_DESCRIPTIONS?.[selectionId]);
    }
  };
  const handleClickLoadConvo = e => {
    e.preventDefault();
    setMessages(currentHistory);
  };

  const handleReset = () => {
    setInputMessage(''); // Clear input after sending
    setMessages(getInitialMessages(type, name));
    dispatch(updateMessages([], type));
  };

  const handleKeyDown = e => {
    const { which: keycode } = e;

    if (keycode === KEYCODE_MAP.ENTER) {
      e.preventDefault();
      sendMessage(inputMessage);
    }
  };

  const handleCloseDialog = () => {
    dispatch(closeAIDialog());
    // TODO: Decide on whether to keep resetting state or not
    // dispatch(updateMessages([], type));
  };

  const handleGetSelectedText = () => {
    if (onClick) {
      onClick(selectedText);
    }
    dispatch(setAIContent(selectedText));
    dispatch(showDrawer({ drawerType: type === 'email' ? 'emailForm' : 'textForm' }));
    dispatch(openAIDialog());

    handleCloseDialog();
  };

  const handleClickUse = () => {
    const content = lastMessage.content;
    const parsedContent = content.includes('Dear {{contact.primaryPerson.firstName}}')
      ? content.slice(content.indexOf('Dear {{contact.primaryPerson.firstName}}'))
      : content;
    if (onClick) {
      onClick(parsedContent);
    }
    dispatch(setAIContent(parsedContent));
    dispatch(showDrawer({ drawerType: type === 'email' ? 'emailForm' : 'textForm' }));
    dispatch(openAIDialog());

    handleCloseDialog();
  };

  const handleChangeType = e => {
    const { value: selectedType } = e.target;
    setType(selectedType);
  };

  const formClasses = classnames({
    [styles.form]: shouldShowTypeSelection,
    [styles.formNoHeader]: !shouldShowTypeSelection
  });

  return (
    <Dialog isOpen={isOpen} className={styles.dialog}>
      <DialogHeader title="AI Author" isColored={true} icon="aiAuthor" clearHandler={() => handleCloseDialog()} />
      {!isEulaValid ? (
        <AIEula />
      ) : (
        <Form className={formClasses} id="aiAuthorForm">
          {shouldShowTypeSelection && (
            <FormFieldset label="What type of chat?">
              <div>
                <TaskType>
                  <FormRadio
                    id="aiChatTypeEmail"
                    name="aiChatTypeEmail"
                    label="Email"
                    value="email"
                    icon="email"
                    onChange={handleChangeType}
                    checked={type === 'email'}
                    disabled={isLoading}
                  />
                  <FormRadio
                    id="aiChatTypeText"
                    name="aiChatTypeText"
                    label="Texting"
                    value="texting"
                    icon="texting"
                    onChange={handleChangeType}
                    checked={type === 'texting'}
                    disabled={isLoading}
                  />
                  {/* TODO: Enable template feature later */}
                  {/* <FormRadio
                  id="aiChatTypeTemplate"
                  name="aiChatTypeTemplate"
                  label="Template"
                  value="template"
                  icon="template"
                  onChange={handleChangeType}
                  checked={type === 'template'}
                  disabled={isLoading}
                /> */}
                </TaskType>
              </div>
            </FormFieldset>
          )}
          <AIConvo messages={messages} isLoading={isLoading} />
          <>
            {isLoading ? (
              <ListPlaceholder rows={1} listItemSize={'s'} />
            ) : (
              <FormFieldset>
                {isNewConvo && (
                  <FormRow>
                    <TagGroup>
                      {Object.keys(PRESET_PROMPTS).map((key, index) => {
                        return (
                          <Tag
                            key={key}
                            entityId={`preset${key}`}
                            label={startCase(key)}
                            color={shuffledColors[index]}
                            handleClick={e => handleSuggestions(e, key)}
                            dataCy={buildTestId(AI_TEST_ID, key)}
                          />
                        );
                      })}
                    </TagGroup>
                  </FormRow>
                )}
                {showEnhancement && (
                  <FormRow>
                    <TagGroup>
                      <Tag
                        key={'regenerate'}
                        label={'Regenerate'}
                        color={COLOR_GREEN_LIGHT}
                        handleClick={() => handleStyleSuggestions('regenerate')}
                        dataCy={buildTestId(AI_TEST_ID, 'regenerate')}
                      />
                      <Tag
                        key={'friendly'}
                        label={'More friendly'}
                        color={COLOR_GREEN_LIGHT}
                        handleClick={() => handleStyleSuggestions('friendly')}
                        dataCy={buildTestId(AI_TEST_ID, 'friendly')}
                      />
                      <Tag
                        key={'formal'}
                        label={'More formal'}
                        color={COLOR_BLUE}
                        handleClick={() => handleStyleSuggestions('professional')}
                        dataCy={buildTestId(AI_TEST_ID, 'professional')}
                      />
                      <Tag
                        key={'emoji'}
                        label={'Emoji'}
                        color={COLOR_RED}
                        handleClick={() => handleStyleSuggestions('emoji')}
                        dataCy={buildTestId(AI_TEST_ID, 'emoji')}
                      />
                      <Tag
                        key={'concise'}
                        label={'Concise'}
                        color={COLOR_RED}
                        handleClick={() => handleStyleSuggestions('concise')}
                        dataCy={buildTestId(AI_TEST_ID, 'concise')}
                      />
                      <Tag
                        key={'casual'}
                        label={'Casual'}
                        color={COLOR_RED}
                        handleClick={() => handleStyleSuggestions('casual')}
                        dataCy={buildTestId(AI_TEST_ID, 'casual')}
                      />
                    </TagGroup>
                  </FormRow>
                )}
              </FormFieldset>
            )}
            <FormFieldset>
              <FormRow>
                <FormField
                  id="userInput"
                  type="input"
                  placeholder="What do you want to write today?"
                  value={inputMessage}
                  onChange={onChange}
                  size="xl"
                  disabled={isLoading}
                  onKeyDown={handleKeyDown}
                  ref={inputRef}
                  data-cy={buildTestId(AI_TEST_ID, 'userInput')}
                />
                <Button
                  disabled={!isAskAIEnabled || isLoading}
                  label="Ask AI"
                  ariaLabel="Ask AI"
                  styleType="secondary"
                  icon="aiAuthor"
                  isColored={true}
                  data-cy={buildTestId(AI_TEST_ID, 'askAIButton')}
                  onClick={() => sendMessage(inputMessage)}
                />
              </FormRow>
            </FormFieldset>
          </>
        </Form>
      )}
      <FormFooter className={styles.footer}>
        <div className={styles.footerContainer}>
          <ButtonGroup>
            {hasMessageHistory && (
              <Button
                disabled={isLoading}
                label="Load last chat"
                ariaLabel="Load last chat"
                styleType="white"
                icon="reload"
                onClick={handleClickLoadConvo}
                data-cy={buildTestId(AI_TEST_ID, 'loadLastButton')}
              />
            )}
            {hasMessageHistory && (
              <Button
                disabled={isLoading}
                icon="reset"
                label="Reset"
                ariaLabel="Reset"
                styleType="white"
                onClick={handleReset}
                data-cy={buildTestId(AI_TEST_ID, 'resetButton')}
              />
            )}
            {!isNewConvo && isFieldFilled(selectedText) && (
              <Button
                styleType="white"
                label={`Use selected`}
                ariaLabel={`Use selected`}
                className={styles.toolbarButton}
                icon={type}
                disabled={disableUseInEditor || isLoading}
                onClick={handleGetSelectedText}
                data-cy={buildTestId(AI_TEST_ID, 'useSelected')}
              />
            )}
            {!isNewConvo && (
              <Button
                styleType="primary"
                label={`Use in ${type}`}
                ariaLabel={`Use in ${type}`}
                className={styles.toolbarButton}
                icon={type}
                disabled={disableUseInEditor || isLoading}
                onClick={handleClickUse}
                data-cy={buildTestId(AI_TEST_ID, 'useButton')}
              />
            )}
          </ButtonGroup>
          <div className={styles.disclaimer}>AI technology can make mistakes. Review all content before sending.</div>
        </div>
      </FormFooter>
    </Dialog>
  );
};

AIDialog.propTypes = {
  className: string
};

export default AIDialog;

/** @module */

import axios from 'axios';
import axiosCancel from 'axios-cancel';
import { showMessage } from '../actions/message';
import { API_BASE_URL, API_PATHS, API_SERVICE_PATHS } from '../constants';
import { AUTH_LOGOUT } from '../reducers/authentication';
import { cleanStrOfSymbols, getLocalStorageItem, setLocalStorageItem } from './';
import { checkIfServerIsOnline } from '../actions/serverStatus';

const REQUEST_CACHE_LIMIT = process.env.REQUEST_CACHE_LIMIT || 900000; // 15 mins or 900000 is recommended
const DEFAULT_REQUEST_TIMEOUT = 25000;

/**
 * Set up default headers for our main Axios instance.
 */
const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

axiosCancel(axiosInstance, {
  debug: false
});

/**
 * Gets an identifying string for each API request using a selective set of request options to use with localStorage timestamps.
 * @param {Object} requestOptions - Th request options from request().
 */
export const getRequestIdentifier = requestOptions => {
  const { apiServiceType = '', method, params, path, payload = {} } = requestOptions;

  const { q, ...otherParams } = params || {};
  const query = q ? `q${q.trim()}` : '';

  // we need to stringify params and data from the request to create a unique identifier
  // we also remove any non-alphanumeric chars
  const cleanIdentifier = cleanStrOfSymbols(
    `${method}${apiServiceType}${path}${JSON.stringify(otherParams)}${JSON.stringify(payload)}`
  );

  // We do not clean the search query, as symbols in search are important - such as dashes in phone number.
  const requestIdentifier = `${cleanIdentifier}${query}`;

  return requestIdentifier;
};

/**
 * Checks localStorage for the requestIdentifier's timestamp, and decides whether the request should be aborted
 * @param {String} requestIdentifier - ID generated from request options.
 * @param { Boolean } forceRefresh - A boolean used to force a refresh of the cached request.
 * @param {Number} [cacheThreshold=REQUEST_CACHE_LIMIT]  - a time limit in milliseconds, default is 15 mins
 */
const requestShouldBeCancelled = (requestIdentifier, forceRefresh = false, cacheThreshold = REQUEST_CACHE_LIMIT) => {
  const now = Date.now();
  const lastRequested = getLocalStorageItem(requestIdentifier);

  return !forceRefresh && lastRequested && now - lastRequested < cacheThreshold;
};

/**
 * Gets the base URL for an Axios request (usually the API domain).
 * @param {String} baseUrlKey - A key used to select the correct base URL domain.
 */
const getBaseUrl = baseUrlKey => {
  // Fallback to the svc API_BASE_URL if a baseUrl can't be found;
  return API_BASE_URL[baseUrlKey] || API_BASE_URL.svc;
};

/**
 * Gets the path used in an API request (usually to select a particular service path).
 * @param {Object} options - Options used to select the proper path.
 * @param {String} [options.apiServiceType] - Usually the .Net service path or ather service sub-path.
 * @param {String} [options.baseUrlKey] - A key used to generate the proper path.
 * @param {String} [options.path] - A actual target path fragment used in the API request.
 */
const getPath = options => {
  const { apiServiceType = null, baseUrlKey, path } = options || {};

  if (!path) {
    return '';
  }

  const PATHS = {
    api: `${API_PATHS[apiServiceType]}/${path}`,
    svc: `${API_SERVICE_PATHS[apiServiceType]}/${path}`,
    sync: `${API_SERVICE_PATHS[apiServiceType]}/${path}`,
    default: path
  };

  return PATHS[baseUrlKey] || PATHS.default;
};

/**
 * Sets up Axios request default headers for the user, and also add pre and post request interceptors
 * to check whether the request should be cached or cancelled
 * @param {Object} user - we only document the used keys from the user object
 * @param {String} [user.loginName] - a string identifier for user
 * @param {String} [user.token] - the access token for the current user
 */
export const setRequestDefaults = user => {
  const { loginName, token } = user;

  if (!token) {
    // we abort setting up request defaults when the user is not logged in
    return;
  }

  axiosInstance.defaults.headers.common['loginName'] = loginName;
  axiosInstance.defaults.headers.common['token'] = token;
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  axiosInstance.interceptors.request.use(config => {
    const { cacheThreshold, forceRefresh, requestId } = config;

    // check to see if the request should be cancelled
    if (requestShouldBeCancelled(requestId, forceRefresh, cacheThreshold)) {
      axiosInstance.cancel(requestId);

      return Promise.reject({
        exceptionType: 'Request Cancelled',
        message: `Using cached data, request cancelled for ${requestId}`
      });
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    response => {
      const { config } = response;
      const { requestId, shouldBeCached } = config;

      // add a cache marker to localStorage for the request
      if (shouldBeCached) {
        setLocalStorageItem(requestId, Date.now());
      }

      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

/**
 * Removes the default Axios request headers on axiosInstance and is used in the Axios option transformRequest.
 * @param {Object} data - The Axios request data.
 * @param {String} headers - The Axios request headers.
 * @param {String} baseUrlKey - A key used to ID a set of API calls for further request transformation.
 */
const removeDefaultHeaders = (data, headers, baseUrlKey) => {
  delete headers.common.Authorization;
  delete headers.common.loginName;
  delete headers.common.token;

  if (baseUrlKey === 'snapshot') {
    // To use the Market Snapshot service, the data needs to be stringified in addition to the header removal.
    return JSON.stringify(data);
  }

  return data;
};

/**
 * A request method used to wrap Axios.
 * @param {Object} options - Options in making a request.
 * @param {String} [options.apiServiceType] - A key to identify which .Net service the path uses - see /src/constants/API_SERVICE_PATHS for more info.
 * @param {String} [options.baseUrl] - To be used infrequently, this override will allow you to bypass the lookup in getBaseUrl().
 * @param {String} [options.baseUrlKey=svc] - A key used to look up the base URL used by Axios, and in the selection of the requestPath.
 * @param {Number} [options.cacheThreshold] - A cache length for the request expressed in milliseconds.
 * @param {Boolean} [options.forceRefresh=false] - A flag to force the API call to bypass the the request cache.
 * @param {Object} [options.headers] - A set of headers, in addition to the default headers set in the Axios instance, to be use in the request.
 * @param {String} [options.method=GET] - Standard request method.
 * @param {Function} [options.onDownloadProgress] - A callback for download progress.
 * @param {Function} [options.onUploadProgress] - A callback for upload progress.
 * @param {Object} [options.params] - Parameters to use with the request.
 * @param {String} [options.path] - The last fragment of the full request path.
 * @param {Object} [options.payload] - A data payload to send with the request.
 * @param {String} [options.responseType] - The type of data that the server will respond with (not used often, mainly for blobs, as json is the default).
 * @param {Boolean} [options.shouldBeCached=true] - A flag to disable the caching of the request.
 * @param {Boolean} [options.shouldRemoveDefaultHeaders=false] - A flag to remove the default headers set for the Axios instance for the given request.
 * @param {Number} [options.timeout=DEFAULT_REQUEST_TIMEOUT] - The request's timeout length.
 */
export const request = options => {
  const {
    apiServiceType,
    baseUrl, // To be used infrequently. Different than baseURL below.
    baseUrlKey = 'svc',
    cacheThreshold,
    forceRefresh = false,
    headers = {},
    method = 'GET',
    onDownloadProgress,
    onUploadProgress,
    params,
    path,
    payload,
    responseType,
    shouldBeCached = true,
    shouldRemoveDefaultHeaders = false,
    timeout = DEFAULT_REQUEST_TIMEOUT
  } = options;

  const baseURL = baseUrl || getBaseUrl(baseUrlKey);

  if (!baseURL) {
    throw { message: 'A baseUrlKey or baseUrl has not been set for your request.' };
  }

  const requestPath = getPath({ apiServiceType, baseUrlKey, path });

  // Axios will remove content-type if data is undefined
  const axiosOptions = {
    baseURL,
    cacheThreshold,
    data: payload,
    forceRefresh,
    headers,
    method,
    onDownloadProgress,
    onUploadProgress,
    params,
    requestId: getRequestIdentifier({ apiServiceType, method, params, path, payload }),
    responseType,
    shouldBeCached,
    timeout,
    url: requestPath
  };

  if (shouldRemoveDefaultHeaders) {
    // Certain API services fail with the default headers. For those given requests, we remove them.
    axiosOptions.transformRequest = [(data, headers) => removeDefaultHeaders(data, headers, baseUrlKey)];
  }

  return axiosInstance(axiosOptions);
};

/**
 * Handles a request error and dispatches the showMessage method
 * @param {Object} error - the standard error or server sent exception object.
 * @param {Callback} dispatch - the redux dispatch method used to show the error.
 * @param {Boolean} useTimer - whether the error toast should automatically close.
 * @param {String} customMessage - a custom error message to show in the error toast.
 * @param {Object} action - An action object with label and url keys.
 */
export const requestError = (error, dispatch, useTimer = true, customMessage, action) => {
  if (error.message === 'Access token is not found.') {
    // Log the user out if access token does not exist
    dispatch({
      type: AUTH_LOGOUT
    });
  }

  const { label: actionLabel, url: actionUrl } = action || {};
  const { exceptionType, id, message, response } = error;
  const { data } = response || {};

  if (exceptionType === 'Request Cancelled' || message?.includes('cancelRequest')) {
    // we check to see if it is an axios cancel message and catch it if so
    // we don't want to raise a toast for planned aborts
    // console.warn(message);
    return;
  }

  if (exceptionType) {
    console.error(`${exceptionType}: ${message}`);
  } else {
    const consoleError = customMessage ? { message: customMessage } : error;
    console.error(consoleError);
  }

  // check whether the server is online for 404s
  if (response && response.status === 404 && message !== 'Server Status Endpoint Error') {
    dispatch(checkIfServerIsOnline());
  }

  useTimer = error && error.exceptionType !== 'LoginException' && useTimer ? true : false;

  const friendlyMessage = customMessage || data?.Message || data?.ErrorMsg || message;

  dispatch(showMessage({ exceptionType, id, message: friendlyMessage, actionLabel, actionUrl }, useTimer));
};

/**
 * A request wrapper for working with Nylas requests.
 */
export const nylasRequest = async requestOptions => {
  const { data } = await request(requestOptions);
  const { exception, rawResponse, statusCode } = data;

  if (exception || statusCode !== 200) {
    throw exception;
  }

  return JSON.parse(rawResponse);
};

/**
 * Request the index.html file - used to check the version number.
 */
export const requestIndexHtml = () => {
  const timestamp = Date.now();
  const path = `index.html`;
  const options = {
    method: 'GET',
    requestId: getRequestIdentifier({ path }),
    url: `${path}?${timestamp}`,
    shouldBeCached: true
  };

  return axiosInstance(options);
};

/** @module */

import {
  ADD_PICKLIST_DATA,
  MLS_CREDS_ADD,
  MLS_CREDS_DELETE,
  MLS_CREDS_FAIL,
  MLS_CREDS_LOADING,
  MLS_CREDS_LOADING_FINISHED,
  MLS_CREDS_SUCCESS,
  MLS_CREDS_UPDATE,
  MLS_SOURCES_FAIL,
  MLS_SOURCES_LOADING,
  MLS_SOURCES_LOADING_FINISHED,
  MLS_SOURCES_SUCCESS
} from '../reducers/mls';
import { getMergedEntities, request, requestError } from '../utils';
import { showMessage } from './message';
import { MLS_SOURCES_ERROR_MESSAGE, MLS_CREDS_ERROR_MESSAGE } from '../utils/mlsBoards';

const credentialsPath = 'mls/credentials';
const sourcesPath = 'mls/sources';

/**
 * Request for MLS credentials
 */
export const getMlsCredentials = () => {
  const getMlsCredentialsOptions = {
    baseUrlKey: 'api',
    cacheThreshold: 7200000, // 2 hrs
    path: credentialsPath,
    shouldBeCached: true
  };

  return async dispatch => {
    dispatch({
      type: MLS_CREDS_LOADING
    });

    try {
      const response = await request(getMlsCredentialsOptions);
      const data = getMergedEntities([], response.data, { idKey: 'source' });

      if (response.status === 200) {
        dispatch({
          type: MLS_CREDS_SUCCESS,
          data: data
        });
      } else {
        dispatch({
          type: MLS_CREDS_FAIL
        });
      }
    } catch (error) {
      const { message } = error;
      // When the request was canceled due to caching
      if (!message.includes('cached')) {
        dispatch({
          type: MLS_CREDS_FAIL
        });
      } else {
        dispatch({
          type: MLS_CREDS_LOADING_FINISHED
        });
      }

      requestError(error, dispatch, true, MLS_CREDS_ERROR_MESSAGE);
    }
  };
};

export const addMlsCredential = ({ source, username, password, accountType, agentMlsId, officeMlsId }) => {
  const MLS_ACCOUNT_TYPES = { agent: 1, master: 2 };
  const accountTypeValue = MLS_ACCOUNT_TYPES[accountType];

  return async dispatch => {
    const payload = {
      source,
      username,
      password,
      accountType: accountTypeValue,
      agentMlsId: accountType === 'agent' ? agentMlsId : null,
      officeMlsId
    };
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: credentialsPath,
      payload: payload,
      shouldBeCached: false
    };

    try {
      const response = await request(options);

      if (response.status === 201) {
        dispatch({
          type: MLS_CREDS_ADD,
          data: response.data
        });
        dispatch(showMessage({ message: 'New MLS credentials added successfully.', type: 'success' }, true));
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const deleteMlsCredential = source => {
  const deletePath = `${credentialsPath}/${source}`;
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: deletePath,
      shouldBeCached: false
    };
    try {
      const response = await request(options);

      if (response.status === 204) {
        dispatch({
          type: MLS_CREDS_DELETE,
          source: source
        });
        dispatch(showMessage({ message: 'MLS credentials deleted successfully.', type: 'success' }, true));
      }
    } catch (error) {
      requestError(error, dispatch, false);
    }
  };
};

export const updateMlsCredential = options => {
  const { source, username, password, accountType, agentMlsId, officeMlsId } = options;

  return async dispatch => {
    const payload = {
      source,
      username,
      password,
      accountType,
      agentMlsId: accountType === 1 ? agentMlsId : null,
      officeMlsId
    };
    const options = {
      baseUrlKey: 'api',
      method: 'PUT',
      path: credentialsPath,
      payload: payload,
      shouldBeCached: false
    };

    try {
      const response = await request(options);

      if (response.status === 200) {
        dispatch({
          type: MLS_CREDS_UPDATE,
          data: response.data
        });
        dispatch(showMessage({ message: 'MLS credentials updated successfully.', type: 'success' }, true));
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

/**
 * Request for MLS sources
 */
export const getMlsSources = () => {
  const getMlsSourcesOptions = {
    baseUrlKey: 'api',
    cacheThreshold: 7200000, // 2 hrs
    path: sourcesPath,
    shouldBeCached: true
  };

  return async dispatch => {
    dispatch({
      type: MLS_SOURCES_LOADING
    });

    try {
      const response = await request(getMlsSourcesOptions);
      const data = getMergedEntities([], response.data, { idKey: 'mlsCode' });
      if (response.status === 200) {
        dispatch({
          type: MLS_SOURCES_SUCCESS,
          data: data
        });
      } else {
        dispatch({
          type: MLS_SOURCES_FAIL
        });
      }
    } catch (error) {
      const { message } = error;
      // When the request was canceled due to caching
      if (!message.includes('cached')) {
        dispatch({
          type: MLS_SOURCES_FAIL
        });
      } else {
        dispatch({
          type: MLS_SOURCES_LOADING_FINISHED
        });
      }

      requestError(error, dispatch, true, MLS_SOURCES_ERROR_MESSAGE);
    }
  };
};

/**
 * Request for getting Hestia MLS picklist
 * @TODO Soon to be deprecated
 */
export const getMlsPicklist = mlsBoard => {
  const path = `ms/getmlspicklist/${mlsBoard}`;

  const getMlsCredentialsOptions = {
    baseUrlKey: 'api',
    cacheThreshold: 7200000, // 2 hrs
    path: path,
    shouldBeCached: true
  };

  return async dispatch => {
    try {
      const response = await request(getMlsCredentialsOptions);

      const { data, status } = response;
      const picklistData = [{ ...data, name: mlsBoard }];
      if (status === 200) {
        dispatch({
          type: ADD_PICKLIST_DATA,
          data: picklistData
        });

        return data;
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Request for getting Hestia MLS picklist
 */
export const getCsiMlsPicklist = mlsBoard => {
  const path = `ms/getcsimlspicklist/${mlsBoard}`;

  const getMlsCredentialsOptions = {
    baseUrlKey: 'api',
    cacheThreshold: 7200000, // 2 hrs
    path: path,
    shouldBeCached: true
  };

  return async dispatch => {
    try {
      const response = await request(getMlsCredentialsOptions);

      const { data, status } = response;
      const picklistData = [{ ...data, name: mlsBoard }];
      if (status === 200) {
        dispatch({
          type: ADD_PICKLIST_DATA,
          data: picklistData
        });

        return data;
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

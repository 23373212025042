import React, { lazy, Suspense } from 'react';
import { Loading } from '../components';
// ToDo: the styles used in this component probably need to be somewhere else
import styles from './Contacts/ContactDetail/ContactDetail.css';

const AIDialog = lazy(() => import('../components/Editor/Toolbar/AIDialog'));
const CalendarSync = lazy(() => import('./Settings/CalendarSync'));
const ContactAdd = lazy(() => import('./Contacts/ContactAdd'));
const ContactCreateTab = lazy(() => import('./Contacts/ContactCreateTab'));
const ContactDetail = lazy(() => import('./Contacts/ContactDetail/ContactDetail'));
const ContactSync = lazy(() => import('./Settings/ContactSync'));
const EmailForm = lazy(() => import('../components/EmailForm/EmailForm'));
const EmailIntegration = lazy(() => import('./Settings/EmailIntegration'));
const EmailSignatures = lazy(() => import('./Settings/EmailSignatures'));
const HomeIntelReports = lazy(() => import('../components/List/HomeIntelReportList'));
const Import = lazy(() => import('./Settings/Import'));
const ImportantDates = lazy(() => import('../components/List/ImportantDatesList'));
const Leads = lazy(() => import('./Settings/Leads'));
const MlsCredentials = lazy(() => import('./Settings/MlsCredentials'));
const MarketSnapshot = lazy(() => import('./Settings/MarketSnapshot'));
const Maintenance = lazy(() => import('./Maintenance/Maintenance'));
const Menu = lazy(() => import('./Menu/Menu'));
const MyInformationForm = lazy(() => import('../components/MyInformationForm/MyInformationForm'));
const NoteDetail = lazy(() => import('./Notes/NoteDetail'));
const NotesForm = lazy(() => import('../components/NotesForm/NotesForm'));
const NoticeTexting = lazy(() => import('./NoticeTexting/NoticeTexting'));
const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword'));
const SetupUser = lazy(() => import('./SetupUser/SetupUser'));
const SignOut = lazy(() => import('./SignOut/SignOut'));
const SmartTargeting = lazy(() => import('../components/SmartTargeting/SmartTargeting'));
const SocialConnect = lazy(() => import('../components/SocialConnect/SocialConnect'));
const SnapshotDetail = lazy(() => import('../components/SnapshotDetail/SnapshotDetail'));
const HomeIntelReportDetail = lazy(() => import('../components/HomeIntel/HomeIntelReportDetail'));
const SnapshotModal = lazy(() => import('../components/SnapshotForm/SnapshotModal'));
const SnapshotReports = lazy(() => import('../components/List/SnapshotReportsList'));
const SnapshotListingAlerts = lazy(() => import('../components/List/SnapshotListingAlertsList'));
const Sso = lazy(() => import('./Sso/Sso'));
const SyncSetup = lazy(() => import('./Settings/SyncSetup'));
const TaskDetail = lazy(() => import('./Tasks/TaskDetail/TaskDetail'));
const TaskPlanDetail = lazy(() => import('./Settings/TaskPlans/Detail/Detail'));
const TaskPlans = lazy(() => import('./Settings/TaskPlans/TaskPlans'));
const TaskPlanTemplates = lazy(() => import('./Settings/TaskPlans/Templates'));
const TransactionDetail = lazy(() => import('./Transactions/Detail/TransactionDetail'));
const Transactions = lazy(() => import('./Transactions/Transactions'));
const TeamManagement = lazy(() => import('./Settings/TeamManagement'));
const Templates = lazy(() => import('./Settings/Templates/Templates'));
const TaskForm = lazy(() => import('../components/TaskForm/TaskForm'));
const TextForm = lazy(() => import('../components/TextForm/TextForm'));
const Texting = lazy(() => import('./Settings/Texting'));
const TextingOptIn = lazy(() => import('./TextingOptIn/TextingOptIn'));
const TPXCalendar = lazy(() => import('./Calendar/TPXCalendar'));
const Unsubscribe = lazy(() => import('./Unsubscribe/Unsubscribe'));
const LeadAutoResponse = lazy(() => import('./Settings/LeadAutoResponse'));
const UISettings = lazy(() => import('./Settings/UISettings/UISettings'));
const WhatsNew = lazy(() => import('./WhatsNew/WhatsNew'));
const WrapupForm = lazy(() => import('../components/WrapupForm/WrapupForm'));
const ZConnect = lazy(() => import('./Settings/ZConnect'));
const Notifications = lazy(() => import('./Settings/Notifications'));
const HomeIntelSettings = lazy(() => import('./Settings/HomeIntel/HomeIntelSettings'));

const MassEmailModal = lazy(() => import('../components/List/MassEmailModal'));

const Pages = {
  calendar: TPXCalendar,
  calendarSync: CalendarSync,
  contactAdd: ContactAdd,
  contactCreateTab: ContactCreateTab,
  contactDetail: ContactDetail,
  contactSync: ContactSync,
  emailForm: EmailForm,
  emailIntegration: EmailIntegration,
  emailSignatures: EmailSignatures,
  homeIntelReports: HomeIntelReports,
  homeIntelReportDetail: HomeIntelReportDetail,
  homeIntelSettings: HomeIntelSettings,
  import: Import,
  importantDates: ImportantDates,
  leads: Leads,
  leadAutoResponse: LeadAutoResponse,
  listingAlerts: SnapshotListingAlerts,
  mlsCredentials: MlsCredentials,
  marketSnapshot: MarketSnapshot,
  maintenance: Maintenance,
  menu: Menu,
  myInfo: MyInformationForm,
  noteDetail: NoteDetail,
  notesForm: NotesForm,
  updateNotesForm: NotesForm,
  noticeTexting: NoticeTexting,
  resetPassword: ResetPassword,
  setupUser: SetupUser,
  signOut: SignOut,
  smartTargeting: SmartTargeting,
  socialConnect: SocialConnect,
  snapshotDetail: SnapshotDetail,
  snapshotForm: SnapshotModal,
  snapshotReports: SnapshotReports,
  updateSnapshotForm: SnapshotModal,
  sso: Sso,
  syncSetup: SyncSetup,
  taskDetail: TaskDetail,
  transactionDetail: TransactionDetail,
  transactions: Transactions,
  taskForm: TaskForm,
  taskPlanDetail: TaskPlanDetail,
  taskPlans: TaskPlans,
  taskPlanTemplates: TaskPlanTemplates,
  teamManagement: TeamManagement,
  templates: Templates,
  textForm: TextForm,
  texting: Texting,
  textingOptIn: TextingOptIn,
  unsubscribe: Unsubscribe,
  uiSettings: UISettings,
  whatsNew: WhatsNew,
  massEmailModal: MassEmailModal,
  wrapupForm: WrapupForm,
  zConnect: ZConnect,
  notifications: Notifications,
  aiChat: AIDialog
};

const LoadingPanel = () => {
  return (
    <div className={styles.loadingPanel}>
      <Loading />
    </div>
  );
};

export const LazyPage = props => {
  const { page, ...ownProps } = props;

  const Page = Pages[page];
  return (
    <Suspense fallback={<LoadingPanel />}>
      <Page {...ownProps} />
    </Suspense>
  );
};

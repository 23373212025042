// In the navbar on mobile and desktop.
const NAV_LINKS_GROUP_1 = [
  {
    url: '/dashboard',
    label: 'Home',
    icon: 'dashboardChart'
  },
  {
    url: '/contacts',
    label: 'Contacts',
    icon: 'addressbook'
  },
  {
    url: '/calendar',
    label: 'Calendar',
    icon: 'calendar'
  },
  {
    url: '/tasks',
    label: 'Tasks',
    icon: 'tasks'
  },
  {
    url: '/transactions',
    label: 'Transactions',
    icon: 'transaction'
  }
];

// In the navbar on desktop only.
const NAV_LINKS_GROUP_2 = [
  {
    url: '/marketing',
    label: 'Marketing',
    icon: 'marketing'
  },
  {
    url: '/notes',
    label: 'Notes',
    icon: 'notes'
  }
];

const MOBILE_NAV_LINKS = [
  ...NAV_LINKS_GROUP_1,
  {
    url: '/menu',
    label: 'Menu',
    icon: 'menu'
  }
];
const ALL_NAV_LINKS = [...NAV_LINKS_GROUP_1, ...NAV_LINKS_GROUP_2];

const SETTINGS_LINK = [
  {
    url: '/settings',
    label: 'Settings',
    icon: 'search'
  }
];

const SETTINGS_NAV = [
  {
    url: '/settings/my-information',
    label: 'My Information',
    icon: 'myinfo'
  },
  {
    url: '/settings/notifications',
    label: 'Notifications',
    icon: 'notifications'
  },
  {
    url: '/settings/calendar-sync',
    label: 'Calendar Sync',
    icon: 'calendarsync'
  },
  {
    url: '/settings/contact-sync',
    label: 'Contact Sync',
    icon: 'contactsync'
  },
  {
    url: '/settings/homeintel-settings',
    label: 'HomeIntel Settings',
    icon: 'homeintelsettings'
  },
  {
    url: '/settings/import',
    label: 'Import Contacts',
    icon: 'upload'
  },
  {
    url: '/settings/email-integration',
    label: 'Email Integration',
    icon: 'emailsetup'
  },
  {
    url: '/settings/email-signatures',
    label: 'Email Signatures',
    icon: 'signature'
  },
  {
    url: '/settings/google-sync',
    label: 'Google Calendar Sync',
    icon: 'google'
  },
  {
    url: '/settings/lead-providers',
    label: 'Lead Providers',
    icon: 'leadsetup'
  },
  {
    url: '/settings/market-snapshot',
    label: 'Market Snapshot',
    icon: 'snapshot'
  },
  {
    url: '/settings/mls-credentials',
    label: 'MLS Credentials',
    icon: 'housekey'
  },
  {
    url: '/settings/team-management',
    label: 'Team Management',
    icon: 'team'
  },
  {
    url: '/settings/texting',
    label: 'Texting',
    icon: 'textingsetup'
  },
  {
    url: '/settings/ui-settings',
    label: 'UI Settings',
    icon: 'colors'
  },
  {
    url: '/settings/z-connect',
    label: 'Zillow Connect',
    icon: 'zillow'
  }
];

export { ALL_NAV_LINKS, NAV_LINKS_GROUP_1, NAV_LINKS_GROUP_2, MOBILE_NAV_LINKS, SETTINGS_LINK, SETTINGS_NAV };

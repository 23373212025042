import React, { Component } from 'react';
import { Location, Redirect, Router } from '@reach/router';
import classnames from 'classnames';

import { Container, Footer } from '../../components';
import { SignIn, LazyPage } from '../../pages';

import styles from './Layout.css';

export class Layout extends Component {
  render() {
    return (
      <Location>
        {({ location }) => {
          const basePath = location?.pathname.split('/')[1];
          const excludedRoutesForStyles = [
            'unsubscribe',
            'setupuser',
            'sign-out',
            'sso',
            'reset-password',
            'maintenance',
            'texting-opt-in'
          ];

          const isSignInPage = !excludedRoutesForStyles.includes(basePath);

          const mainContainerClasses = classnames({
            [styles.mainContainer]: true,
            [styles.isSignIn]: isSignInPage
          });

          return (
            <Container role="main">
              <Container id="mainContainer" className={mainContainerClasses}>
                <Router>
                  {/* Chatter needs this redirect to support legacy texting links. */}
                  <Redirect from="contacts/:contactId/send-text/texts" to="contacts/:contactId/texts" noThrow />
                  <SignIn path="/" default />
                  <LazyPage path="/unsubscribe/*" page="unsubscribe" />
                  <LazyPage path="/texting-opt-in/*" page="textingOptIn" />
                  <LazyPage path="/setupuser" page="setupUser" />
                  <LazyPage path="/sign-out" page="signOut" />
                  <LazyPage path="/sso/*" page="sso" />
                  <LazyPage path="/reset-password" page="resetPassword" />
                  <LazyPage path="/maintenance" page="maintenance" />
                </Router>
              </Container>
              {!isSignInPage && <Footer />}
            </Container>
          );
        }}
      </Location>
    );
  }
}

export default Layout;

import { request, requestError, setRequestDefaults } from '../utils';
import { checkAuthCredentials } from './authentication';
import {
  SSO_AUTH_REQUEST,
  SSO_AUTH_SUCCESS,
  SSO_AUTH_FAIL,
  SSO_FETCH_TP8I_TOKEN_REQUEST,
  SSO_FETCH_TP8I_TOKEN_SUCCESS,
  SSO_FETCH_TP8I_TOKEN_FAIL
} from '../reducers/sso';
import { AUTH_SET_USER } from '../reducers/authentication';
import { navigate } from '@reach/router';
import { getPreferences } from './preferences';
import { MARKETING_URI } from '../constants/uris';

const navigatePassthru = ({ contactId, navDestination, reportInstanceId = null }) => {
  const contactDestinations = {
    contactEmail: `/contacts/${contactId}/emails`,
    contactSnapshot: `/contacts/${contactId}/snapshots`
  };

  const otherDestinations = {
    settingscontactsync: `/settings/contact-sync`,
    snapshot: MARKETING_URI,
    socialConnect: '/marketing/social-connect',
    sellerReports: '/marketing/home-intel',
    default: '/'
  };

  let destination;

  if (contactId) {
    destination = reportInstanceId
      ? `${contactDestinations[navDestination]}/market-snapshots/${reportInstanceId}`
      : contactDestinations[navDestination];
  } else {
    if (navDestination) {
      destination = reportInstanceId
        ? `${otherDestinations[navDestination]}/market-snapshots/${reportInstanceId}`
        : otherDestinations[navDestination];
    } else {
      destination = otherDestinations.default;
    }
  }

  navigate(destination);
};

export const ssoAuthenticate = ({
  appId,
  contactId = null,
  navDestination = null,
  reportInstanceId = null,
  token,
  uniqueId
}) => {
  return async (dispatch, getState) => {
    const isAuthenticated = getState().userProfile.userInfo;

    if (!isAuthenticated) {
      dispatch({
        type: SSO_AUTH_REQUEST
      });

      const requestOptions = {
        apiServiceType: 'sso',
        baseUrlKey: 'api',
        method: 'POST',
        path: `authenticate`,
        payload: {
          applicationId: appId,
          uniqueId: uniqueId,
          authToken: token
        }
      };

      try {
        const { data } = await request(requestOptions);
        const { needSetup } = data;

        // We check to make sure the data returned from authentication has a token and loginName.
        checkAuthCredentials(data);

        // we need to keep the request defaults (loginName, token) fresh
        setRequestDefaults(data);

        dispatch({ type: SSO_AUTH_SUCCESS });

        if (!needSetup) {
          await dispatch(getPreferences());
        }

        dispatch({
          type: AUTH_SET_USER,
          data
        });

        navigatePassthru({ contactId, navDestination, reportInstanceId });
      } catch (error) {
        dispatch({
          type: SSO_AUTH_FAIL
        });
        requestError(error, dispatch);

        return;
      }
    } else {
      navigatePassthru({ contactId, navDestination, reportInstanceId });
    }
  };
};

export const ssoToTp8i = () => {
  return async dispatch => {
    dispatch({
      type: SSO_FETCH_TP8I_TOKEN_REQUEST
    });

    const requestOptions = {
      baseUrlKey: 'api',
      apiServiceType: 'sso',
      method: 'GET',
      path: `tp8iurl`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      // we need to keep the request defaults (loginName, token) fresh
      setRequestDefaults(data);

      dispatch({ type: SSO_FETCH_TP8I_TOKEN_SUCCESS });
      window.open(data.ssoUrl);
    } catch (error) {
      dispatch({
        type: SSO_FETCH_TP8I_TOKEN_FAIL
      });
      requestError(error, dispatch);
    }
  };
};

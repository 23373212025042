import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Layout, LayoutAuthenticated } from '../../layouts';
import { MediaQueryProvider } from '../../components/MediaQueryProvider/MediaQueryProvider';
export class Authentication extends Component {
  render() {
    const { user } = this.props;
    const { token: isAuthenticated } = user;

    // a user isAuthenticated if there is a token set for the user
    return <MediaQueryProvider>{isAuthenticated ? <LayoutAuthenticated /> : <Layout />}</MediaQueryProvider>;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Authentication);

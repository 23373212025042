import { showMessage } from './message';
import { API_BASE_URL } from '../constants';
import { request, requestError } from '../utils';

export const IMAGE_FILE_SIZE_FAILURE_MESSAGE = 'Images must be less than 1.3 MB in size.';

export const isImageFileSizeSupported = file => {
  return file.size >= 1300000 ? false : true;
};

/**
 * Upload image file
 * @param {Object} options - data object for file uploads.
 * @param {String} options.entityId - an ID used to further differentiate the image, such as Nylas email ID, or contact ID.
 * @param {String} options.file - the file object.
 * @param {String} options.userId - user ID.
 */
export const uploadImage = options => {
  return async dispatch => {
    const { entityId, file, userId, awsToken } = options;
    const { name: fileName, type: fileType } = file;
    const safeFileName = fileName.replace(/[^a-zA-Z0-9_.]+/g, ''); // remove spaces and most other chars except _ and .
    const timestamp = new Date().getTime();

    if (!file || !userId) {
      return;
    }

    const imageNameFragments = entityId
      ? [userId, entityId, timestamp, safeFileName]
      : [userId, timestamp, safeFileName];
    const imageName = imageNameFragments.join('-');

    const params = {
      fileName: imageName,
      contentType: fileType
    };

    const requestOptions = {
      baseUrlKey: 'edge',
      method: 'POST',
      path: 'crm',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${awsToken}`
      },
      params,
      payload: file,
      shouldBeCached: false,
      shouldRemoveDefaultHeaders: true,
      timeout: 50000
    };

    try {
      const response = await request(requestOptions);
      if (response.status === 200) {
        return { url: `${API_BASE_URL.edge}/crm/${imageName}` };
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};

export const handleImageUpload = (e, userId, entityId, awsToken) => {
  return async dispatch => {
    const { target } = e;
    const { files } = target;
    const file = files[0];

    if (!isImageFileSizeSupported(file)) {
      dispatch(showMessage({ message: IMAGE_FILE_SIZE_FAILURE_MESSAGE, type: 'error' }, false));
      return;
    }

    const fileData = await dispatch(uploadImage({ entityId, file, userId, awsToken })).then(imageData => {
      return imageData;
    });

    return fileData;
  };
};

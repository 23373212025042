/** @module */

import { navigate } from '@reach/router';
import {
  HOME_INTEL_HAS_RETRIEVED_SETTINGS,
  HOME_INTEL_SAVE_SETTINGS,
  HOME_INTEL_SETTINGS_COOLDOWN,
  HOME_INTEL_SUBSCRIPTION_REQUESTED
} from '../reducers/homeintel';
import { request, requestError } from '../utils';
import { getContactDetail } from './contacts';
import { showMessage } from './message';

/**
 * Gets the user's preferences from the home intel preference API.
 * If success, the preferences are stored in Redux.
 * If fails, shows error message via <Toast />.
 */
export const getHomeIntelPreferences = () => {
  return async dispatch => {
    const options = {
      method: 'GET',
      baseUrlKey: 'api',
      path: 'homeintel/settings',
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: HOME_INTEL_HAS_RETRIEVED_SETTINGS,
        data: true
      });

      if (!data?.settings) {
        // If the user has no preferences, we return out.
        return;
      }

      dispatch({
        type: HOME_INTEL_SAVE_SETTINGS,
        data: data
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Saves the user's preferences from the generic settings API.
 * If success, saves the preferences.
 * If fails, shows error message via <Toast />.
 * @param {Object} preferences - data object which includes the whole, newly updated preferences object.
 * @param {Object} prevPreferences - data object which includes the previous preferences object.
 * @param {Boolean} silent - boolean which determines if preferences saved message should be disabled.
 */
export const saveHomeIntelPreferences = (preferences, prevPreferences, silent = false) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'homeintel/settings',
      payload: JSON.stringify(preferences),
      shouldBeCached: false,
      timeout: 60000
    };

    // We optimistically dispatch the preference save.
    dispatch({
      type: HOME_INTEL_SAVE_SETTINGS,
      data: preferences
    });

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!silent) {
        dispatch(showMessage({ message: 'HomeIntel settings saved successfully.', type: 'success' }, true));
      } // useTimer set to true

      dispatch({
        type: HOME_INTEL_SETTINGS_COOLDOWN,
        data: Date.now()
      });

      return true;
    } catch (error) {
      // If it errors, we fallback to the prevPreferences.
      dispatch({
        type: HOME_INTEL_SAVE_SETTINGS,
        data: prevPreferences
      });

      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Gets the contact's HomeIntel events from the home intel API.
 * If success, the events are returned.
 * If fails, shows error message via <Toast />.
 */
export const getHomeIntelEvents = contactId => {
  return async dispatch => {
    const options = {
      method: 'GET',
      baseUrlKey: 'api',
      path: 'homeintel/events',
      params: {
        contactId
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Send a one-off HomeIntel report to contact.
 * do not save anything to local store.
 * @param {*} contactId
 * @param {*} propertyId
 * @param {*} silent
 * @returns
 */
export const sendOneOffReport = (contactId, propertyId = null, silent = false) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'homeintel/reports/one-off',
      payload: {
        contactId: contactId,
        propertyId: propertyId
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!silent) {
        dispatch(
          showMessage({ message: data?.message, type: data?.status === 'Requested' ? 'success' : 'error' }, true)
        );
      }

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Triggers report generation for imported contacts based on the provided import sessionId
 * @param {string} sessionId the import session_id
 */
export const triggerReportGenerationFromImport = sessionId => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `contacts/registerHIReportForImport?importSessionId=${sessionId}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Gets HomeIntel marketing events.
 * If success, the events are returned.
 * If fails, shows error message via <Toast />.
 */
export const getHomeIntelMarketingEvents = (startDate, endDate) => {
  return async dispatch => {
    const options = {
      method: 'GET',
      baseUrlKey: 'api',
      path: 'homeintel/events/marketing',
      params: {
        startDate,
        endDate
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Send signup request for homeintel.
 * If success, the requested status is saved to store.
 * @param {*} autoSendPreference
 * @param {*} silent
 * @returns
 */
export const sendSignUpRequest = (autoSendPreference = true, silent = false) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'homeIntel/subscription/request',
      params: {
        autoSendPreference: autoSendPreference
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: HOME_INTEL_SUBSCRIPTION_REQUESTED,
        data: true
      });

      if (!silent) {
        if (data?.message) {
          dispatch(showMessage({ message: data.message, type: 'error' }, true));
        } else {
          dispatch(showMessage({ message: 'HomeIntel subscription requested', type: 'success' }, true));
        }
      }

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Toggle the process for Home Intel report scheduling.
 * If success, return true
 * @param {*} reportId
 * @param {*} silent
 * @returns
 */
export const toggleHomeIntelReportSchedule = (reportId, isSuspended, silent = false) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `homeintel/reports/${reportId}/${isSuspended ? 'start' : 'stop'}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!silent) {
        if (data?.length === 0) {
          dispatch(
            showMessage(
              {
                message: `HomeIntel report schedule ${isSuspended ? 'started' : 'stopped'} for this contact`,
                type: 'success'
              },
              true
            )
          );
        } else {
          dispatch(showMessage({ message: data?.message, type: 'error' }, true));
        }
      }

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * This function calls the contactDetail endpoint and redirects agent to the contact HomeIntel tab
 * @param {String} contactId
 */
export const goToHomeIntelContactDetailsTab = contactId => {
  return async dispatch => {
    try {
      dispatch(getContactDetail({ id: contactId, suppressWarning: false })).then(isFound => {
        if (isFound != null) {
          navigate(`/contacts/${contactId}/home-intel`);
        }
        // Don't redirect if not found
      });
    } catch (error) {}
  };
};
